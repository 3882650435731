module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Janssen Medical Cloud","short_name":"JMC","start_url":"/?utm_source=webmanifest-homescreen","background_color":"#FFF","theme_color":"#FFF","display":"minimal-ui","icon":"src/images/favicon-32x32-branded.png","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4975f0d1fd61dffce34bf4b59754cc7a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
